<template>
  <div class="video-box" @click="playbox">
    <div id="dplayer" playsinline webkit-playsinline></div>
  </div>
</template>
<script>
import { playCheck } from "@/api/app.js";
import { sendValidWatched } from "@/api/app.js";
import { mapGetters } from "vuex";
import { optRecord } from "@/utils/optLog.js";
export default {
  props: {
    videoInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      playTime: 0, //播放时间
      playTimer: null,
      player: "",
      mediaInfo: null,
      playable: false, //视频能否播放
      //vipExpire通过过期时间判读是否是会员
      code: 0, /////6020: 金币视频,购买后观看
      ///6021: 免费观看次数已经用完
      ///6028: 非会员无法观看会员视频
      ///6033: 需要加入粉丝团
      firstAddLog: true
    };
  },
  computed: {
    ...mapGetters({
      logicCfg: "logicCfg",
    }),
  },
  created() {
    this.playCheck();
    this.$bus.$on("paySuccess", () => {
      //购买成功回调
      if (this.player.video.paused) {
        //如果视频暂停{
        this.player.play(); //播放视频
        this.playbutton();
      }
      this.playable = true;
    });
  },
  mounted() {
    this.videoInit();
    this.player.video
      .play()
      .then(() => {})
      .catch(() => {
        setTimeout(() => {
          var diplayerLoadingIcon = document.getElementsByClassName(
            "diplayer-loading-icon"
          )[0];
          if (
            this.$route.path == "/fullScreen" &&
            diplayerLoadingIcon &&
            getComputedStyle(diplayerLoadingIcon).display == "none"
          ) {
            this.playbutton();
          }
        }, 300);
      });
  },
  methods: {
    async playCheck() {
      //检查视频能否播放
      let ret = await this.$Api(playCheck, { mediaId: this.videoInfo.id });
      if (ret.code == 200) {
        this.playable = ret.data.data.playable;
        this.code = ret.data.data.code;
        this.mediaInfo = ret.data.data.mediaInfo;
      }
    },
    playbox() {
      //点击视频元素
      if (this.player.video.paused) {
        this.player.video.play();
      } else if (this.player.video.readyState == 4) {
        this.player.video.pause();
      }
    },
    playbutton() {
      var dplayerBezelIcon =
        document.getElementsByClassName("dplayer-bezel-icon")[0];
      if (this.player.video.paused) {
        //如果视频暂停
        dplayerBezelIcon.style.display = "block";
      } else {
        dplayerBezelIcon.style.display = "none";
      }
    },
    setPlatTime(bool) {
      if (bool) {
        this.playTimer = setInterval(() => {
          this.playTime++;
        }, 1000);
      } else {
        clearInterval(this.playTimer);
      }
    },
    playing() {
      this.setPlatTime(true);
    },
    pause() {
      this.setPlatTime(false);
    },
    // 发送有效统计
    sendValidWatchTimes() {
      try {
        let validWatchTimePercent = this.logicCfg.validWatchTimePercent;
        let id = this.mediaInfo.id;
        let playTime = this.mediaInfo.playTime;
        if (
          validWatchTimePercent == null ||
          !this.playTime ||
          !id ||
          !playTime ||
          (playTime * validWatchTimePercent) / 100 > this.playTime
        )
          return;
        this.$Api(sendValidWatched, id);
      } catch (error) {
        console.log(error);
      } finally {
        this.playTime = 0;
      }
    },
    videoInit() {
      // eslint-disable-next-line no-undef
      this.player = new DPlayer({
        container: document.getElementById("dplayer"),
        autoplay: true, //自动播放
        loop: true,
        // volume:0,
        video: {
          url:
            sessionStorage.getItem("baseUrl") +
            "media/m3u8/" +
            this.videoInfo.videoUrl +
            "?Authorization=" +
            localStorage.getItem("Authorization"),
          type: "hls",
        },
        pluginOptions: {
          hls: {
            // hls config
          },
        },
      });
      this.player.on("timeupdate", () => {
        if (
          this.player.video.currentTime > this.videoInfo.prevtime &&
          !this.playable
        ) {
          if (this.firstAddLog) {
            this.firstAddLog = false;
            optRecord(1, this.videoInfo)
          }
          //过了试看时长 并且没有权限播放就暂停视频并且弹窗出来
          this.player.pause(); //暂停视频
          this.playbutton();
          if (this.code == 6028 || this.code == 6021) {
            //如果是会员视频 就弹窗购买会员vip
            this.$emit("viptan");
          } else if (this.code == 6020 || this.code == 6033) {
            //如果是金币视频 就弹出金币购买弹窗 或者是粉丝视频
            this.$emit("jinbitan");
          }
        }
        if (this.firstAddLog && this.mediaInfo) {
          if (this.mediaInfo.playTime > 300) {
            if (this.videoInfo > this.mediaInfo.playTime * 0.1) {
              this.firstAddLog = false;
              optRecord(1, this.mediaInfo);
            }
          } else if (this.videoInfo > 15 ) {
            this.firstAddLog = false;
            optRecord(1, this.mediaInfo);
          }
        }
      });
      this.player.on("play", () => {
        this.playbutton();
      });
      this.player.on("playing", () => {
        this.playing();
      });
      this.player.on("pause", () => {
        this.pause();
        setTimeout(() => {
          var diplayerLoadingIcon = document.getElementsByClassName(
            "diplayer-loading-icon"
          )[0];
          if (
            diplayerLoadingIcon &&
            getComputedStyle(diplayerLoadingIcon).display == "none"
          ) {
            this.playbutton();
          }
        }, 50);
      });
      this.player.on("destroy", () => {
          this.sendValidWatchTimes(); //视频统计接口
      });
    },
  },
  beforeDestroy() {
    this.player.destroy(); //销毁播放器
    this.$bus.$off("paySuccess");
  },
};
</script>
<style lang="scss" scoped>
.video-box,
#dplayer {
  width: 100%;
  min-height: 100%;
  height: 103.1%;
}
#dplayer /deep/ .dplayer-video-wrap {
  pointer-events: none;
}
#dplayer /deep/ .dplayer-controller .dplayer-icons.dplayer-icons-right {
  display: none;
}
#dplayer /deep/ .dplayer-bar-wrap {
  width: calc(100% - 120px);
  left: 60px;
  bottom: 12px;
  pointer-events: auto;
}
#dplayer /deep/ .dplayer-played {
  background: #ffffff !important;
}
#dplayer /deep/ .dplayer-icons {
  left: 10px;
  right: 10px;
}
#dplayer /deep/ .dplayer-controller {
  bottom: 40px;
}
#dplayer /deep/ .dplayer-dtime {
  position: absolute;
  right: 0;
  visibility: visible;
}
#dplayer /deep/ .dplayer-mobile-play {
  pointer-events: none;
}
#dplayer /deep/ .dplayer-time {
  visibility: hidden;
}
#dplayer /deep/ .dplayer-ptime {
  position: absolute;
  left: 0;
  visibility: visible;
}
#dplayer /deep/ .dplayer-bezel-icon {
  background: url("../../assets/png/video_pause.png") center center no-repeat;
  background-size: 50%;
  opacity: 1;
  border-radius: 0;
  display: none;
}
#dplayer /deep/ .dplayer-mobile-play > svg {
  display: none;
}
#dplayer /deep/ .dplayer-notice,
#dplayer /deep/ .dplayer-menu,
#dplayer /deep/ .dplayer-play-icon,
#dplayer /deep/ .dplayer-volume {
  display: none;
}
</style>