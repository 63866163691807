<template>
  <div class="video">
    <swiper class="video-swiper" :options="swiperOption" ref="mySwiper">
      <swiper-slide v-for="(item,index) in vidList" :key="item.id+index">
        <div class="video-all">
          <videobox v-if="pageNum == index" :videoInfo="item" @viptan="videoMemberShow=true" @jinbitan="videoPayShow=true"/>
          <div class="layer flex-between" @click.stop>
            <div class="left" v-show="contentShow">
              <div class="tag"><router-link tag="span" :to="{path:'/searchTagResult',query:{name:items}}" class="tag-w" v-for="(items,indexs) in item.flags" :key="indexs">#{{items}}</router-link></div>
              <div class="title ellipsis" @click="author(item)">@{{item.author&&item.author.nickName}}</div>
              <div class="des">{{item.content}}</div>
              <div class="pay-video" @click="videoPayShowClick(item)" v-show="!item.isVip&&!item.isBuy"><span class="play-icon"></span>购买完整版</div>
            </div>
            <div class="right">
              <div class="care" @click="like(item.bLike)" v-show="contentShow">
                <div class="care-icon" :class="{cared:item.bLike}"></div>
                <p class="cared-num">{{item.likeNum|watchCount}}</p>
              </div>
              <div class="share" @click="shareShow=true" v-show="contentShow">
                <div class="share-icon"></div>
                <div class="share-num">{{item.shareNum|watchCount}}</div>
              </div>
              <div class="eye" :class="{close:!contentShow}" @click="contentShow=!contentShow"></div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="back" @click="$router.go(-1)">
      <span class="back-icon"></span>
    </div>
    <div class="pinglun" @click="commentShow=true;topicInfo=vidList[pageNum]">留下你精彩的评论吧</div>
    <comment v-show="commentShow" :topicInfo="topicInfo" @commentSuccess="commentSuccess" :commentShow="commentShow" @commentClose="commentShow=false"/>
    <share v-if="shareShow" :topicInfo="vidList[pageNum]" @shareSuccess="shareSuccess" :shareShow="shareShow" @shareClose="shareShow=false"/>
    <videopay v-show="videoPayShow" :topicInfo="vidList[pageNum]" @paySuccess="paySuccess" :videoPayShow="videoPayShow" @joinFans="isfanShow=true,videoPayShow=false" @videoPayClose="videoPayShow=false"/>
    <videomember v-show="videoMemberShow" :topicInfo="vidList[pageNum]" :videoMemberShow="videoMemberShow" @videoMemberClose="videoMemberShow=false"/>
    <fandialog @fanClose="isfanShow=false" :isfanShow="isfanShow" :fansGroup="vidList[pageNum].fansGroup" @fanSuc="fanSuc" />
  </div>
</template>
<script>
import {swiper,swiperSlide} from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import videobox from '../widget/video.vue';
import share from '../widget/share.vue';
import videopay from '../widget/videoPay.vue';
import videomember from '../widget/videoMember.vue';
import comment from '../widget/comment.vue';
import fandialog from '../widget/fanDialog.vue';
import {like} from '@/api/app.js';
export default {
  components:{
    comment,
    share,
    videobox,
    swiper,
    swiperSlide,
    videopay,
    videomember,
    fandialog
  },
  data(){
    return{
      player: "",
      topicInfo:{},//给评论模块的视频信息
      vidList:[],//轮播视频列表
      firstComming:1,//为了辨认是否是刷新后第一次进入
      commentShow:false,//评论是否显示
      videoPayShow:false,//金币支付弹窗
      shareShow:false,//分享组件的显示与否
      contentShow:true,//视频弹窗的显示与隐藏
      isfanShow:false,//加入粉丝弹窗显示与隐藏
      videoMemberShow:false,//开通会员视频弹窗
      pageNum:0,//短视频滑动的条数
      videoSize:10,//视频列表分页数量
      list:this.$route.query.name?(JSON.parse(localStorage.getItem('videoList'))&&JSON.parse(localStorage.getItem('videoList')).list||[]):(JSON.parse(localStorage.getItem('oneVideoList'))&&JSON.parse(localStorage.getItem('oneVideoList')).list||[]),//视频列表
      index:this.$route.query.name?(JSON.parse(localStorage.getItem('videoList'))&&JSON.parse(localStorage.getItem('videoList')).index||0):(JSON.parse(localStorage.getItem('oneVideoList'))&&JSON.parse(localStorage.getItem('oneVideoList')).index||0),//进入页面选中当前页
      acIndex:this.$route.query.name?(JSON.parse(localStorage.getItem('videoList'))&&JSON.parse(localStorage.getItem('videoList')).index||0):(JSON.parse(localStorage.getItem('oneVideoList'))&&JSON.parse(localStorage.getItem('oneVideoList')).index||0),//页面实时当前页
      swiperOption:{
        direction : 'vertical',
        on: {
          slideChangeTransitionEnd:() => {
            //切换页数
            //判断是上划还是下划
            this.$nextTick(() => {
              if(this.firstComming !=1||this.index==0){//利用firstComming判断是否是刷新后进入
                let videoList = JSON.parse(localStorage.getItem('videoList'));
                if(this.pageNum < this.$refs.mySwiper.swiper.activeIndex){//下划
                  videoList.index ++;
                  this.acIndex ++;
                }else{//上划
                  videoList.index --;
                  this.acIndex --;
                }
                localStorage.setItem('videoList',JSON.stringify(videoList));
              }
              this.firstComming = 2;
              this.pageNum = this.$refs.mySwiper.swiper.activeIndex;
              if(this.pageNum == this.vidList.length - 1){//如果当滑动到倒数第一条视频的时候就请求下一页视频数据
                if(this.acIndex + this.videoSize < this.list.length - 1){//往后添加页码 如果后面还有10条数
                  this.vidList = this.vidList.concat(this.list.slice(this.acIndex + 1,this.acIndex + this.videoSize + 1));//加10条数据
                  if(this.vidList.length >= this.videoSize * 3){//添加到了第三页就删除第一页
                  this.firstComming = 1;
                    this.$refs.mySwiper.swiper.slideTo(this.pageNum - this.videoSize,0,true);//切换到删除第二页的倒数第二条
                    // this.pageNum = this.pageNum - this.videoSize;
                    this.vidList.splice(0,this.videoSize);//删除前10条
                  }
                }else{//如果后面不够10条数了
                  this.vidList = this.vidList.concat(this.list.slice(this.acIndex + 1,this.list.length));//添加剩余的条数
                }
              }else if(this.pageNum==0){//向上翻到第0条第时候
                if(this.acIndex > 0){//往前添加页码 如果前面还有条数
                  this.firstComming = 1;
                  if(this.acIndex - this.videoSize >= 0){//10条数够了
                    this.vidList = this.list.slice(this.acIndex - this.videoSize,this.acIndex).concat(this.vidList);//加10条数据
                    this.$refs.mySwiper.swiper.slideTo(this.pageNum + this.videoSize,0,true);//切换到删除第二页的倒数第二条
                    // this.pageNum = this.pageNum + this.videoSize;
                    if(this.vidList.length >= this.videoSize * 3){
                      this.vidList.splice(this.vidList.length - this.videoSize,this.videoSize);//删除后10条
                    }
                  }else{//条数不够
                    let num = this.acIndex - this.videoSize;
                    this.vidList = this.list.slice(0,this.videoSize + num).concat(this.vidList);//加小于10条数据
                    this.$refs.mySwiper.swiper.slideTo(this.pageNum + (this.videoSize + num),0,true);//切换到删除第二页的倒数第二条
                    // this.pageNum = this.pageNum + (this.videoSize + num);
                  }
                }
              }
            })
          }
        }
      },
    }
  },
  created(){
    //分页开始 每页10条 只要多余20（满足两页）条就开始分页
      if(this.list.length <= this.videoSize * 2){//如果是小于20条
        this.vidList = this.list;
        this.$nextTick(() => {//切换到响应的页数
          this.$refs.mySwiper.swiper.slideTo(this.index,0,true);
        })
      }else{//多余20条 开始分页
        if(this.index <= 1){//如果index小于等于1就直接赋值 不然就将index赋值为第1页（从0开始）
          this.vidList = this.list.slice(0,this.videoSize);
          this.$nextTick(() => {//切换到响应的页数
            this.$refs.mySwiper.swiper.slideTo(this.index,0,true);
          })
        }else{//如果index大于1 就将index赋值为第1页（从0开始）
          this.vidList = this.list.slice(this.index - 1,this.index + this.videoSize);//第一页是11条 后面每页加10条
          this.$nextTick(() => {//切换到响应的页数
            this.$refs.mySwiper.swiper.slideTo(1,0,true);
          })
        }
      }
  },
  methods:{
    paySuccess(num){//购买成功回调
      this.videoPayShow = false;
        if(num){//如果是用券支付的
          let uInfo = JSON.parse(sessionStorage.getItem('userInfo'));//更改个人信息 优惠券列表应该已经用了
          let list = uInfo.videoTickets;
          for(let i = 0;i < list.length; i ++){
            if(num == list[i].gold){
              if(list[i].count > 1){
                list[i].count --;
              }else{
                list.splice(i,1);
              }
            }
          }
          uInfo.videoTickets = list;
          sessionStorage.setItem('userInfo',JSON.stringify(uInfo));
        }
        if(this.$route.query.name){//如果在视频播放页面就要更改local里面的videoList
          this.vidList[this.pageNum].isBuy = true;//实时更改当前页面的购买状态
          let videoList = JSON.parse(localStorage.getItem('videoList'));//更改存的视频列表的状态
          videoList.list[this.acIndex].isBuy = true;
          localStorage.setItem('videoList',JSON.stringify(videoList));//更改存储local里面的购买状态
        }else{//如果是在单视频情况下购买的
          this.vidList[this.pageNum].isBuy = true;//实时更改当前页面的购买状态
          let oneVideoList = JSON.parse(localStorage.getItem('oneVideoList'));//更改存的视频列表的状态
          oneVideoList.list[this.acIndex].isBuy = true;
          localStorage.setItem('oneVideoList',JSON.stringify(oneVideoList));//更改存储local里面的购买状态
        }
    },
    videoPayShowClick(mediaInfo){
      // console.log(mediaInfo);
      //已加入粉丝团
      if (
        mediaInfo.fansGroup &&
        mediaInfo.fansGroup.isMember === true &&
        mediaInfo.fansGift === true
      ) {
        this.$toast("您已加入粉丝团，无需购买");
        return;
      }
      this.videoPayShow = true;
    },
    //粉丝团购买成功
    fanSuc() {
      this.vidList[this.pageNum].fansGroup.isMember = true;//实时更改当前页面的购买状态
      let oneVideoList = JSON.parse(localStorage.getItem('oneVideoList'));//更改存的视频列表的状态
      oneVideoList.list[this.acIndex].fansGroup.isMember = true;
      localStorage.setItem('oneVideoList',JSON.stringify(oneVideoList));//更改存储local里面的购买状态
      this.isfanShow =false;
      this.$toast("加入粉丝团成功");
    },
    author(item){
      // item 4博主 1用户
      if (item.author.type == 4) {
        this.$router.push({
          path: "/bloggerDetails",
          query: {
            id:item.author.id,
          },
        });
      }else{
        this.$router.push({
          path: "/userDetails",
          query: {
            id:item.author.id,
          },
        });
      }
    },
    async like(prop){//点赞
      if(!prop){//如果prop为false 就是点赞
          this.vidList[this.pageNum].bLike = true;
          this.vidList[this.pageNum].likeNum ++;
      }else{//取消点赞
          this.vidList[this.pageNum].bLike = false;
          this.vidList[this.pageNum].likeNum --;
      }
      let req = {
          isLike:!prop,//true为点赞，false为取消点赞
          mediaId:this.vidList[this.pageNum].id,
      }
      let ret = await this.$Api(like,req);
      if(ret.code == 200){
        let videoList = JSON.parse(localStorage.getItem('videoList'));//更改存的视频列表的状态
        videoList.list[videoList.index] = this.vidList[this.pageNum];
        localStorage.setItem('videoList',JSON.stringify(videoList));//更改存储local里面的购买状态
      }else{
          this.$toast(ret.tip);
      }
    },
    shareSuccess(){//分享成功回调
      this.vidList[this.pageNum].shareNum ++;
      let videoList = JSON.parse(localStorage.getItem('videoList'));//更改存的视频列表的状态
      videoList.list[videoList.index] = this.vidList[this.pageNum];
      localStorage.setItem('videoList',JSON.stringify(videoList));//更改存储local里面的购买状态
    },
    commentSuccess(){//评论成功评论条数加一
      this.vidList[this.pageNum].commentNum ++;
      localStorage.setItem('videoList',JSON.stringify(this.vidList));
    },
  },
  beforeDestroy(){
    this.$bus.$off('paySuccess');
  }
}
</script>
<style lang="scss" scoped>
.video-swiper{
  width:100%;
  height:calc(100% - 42px);
  overflow: hidden;
  background: #000;
}
.video-all{
  width:100%;
  height: 100%;
  overflow: hidden;
}
.back{
    position: absolute;
    top:0px;
    left:0;
    width:52px;
    height:52px;
    text-align: center;
    line-height: 62px;
    z-index: 1;
}
.back-icon{
    display:inline-block;
    width:24px;
    height:24px;
    background:url('../../assets/png/back_icon.png') center center no-repeat;
    background-size:100%;
}
.pinglun{
  position:absolute;
  width:100%;
  height:46px;
  line-height: 46px;
  text-indent: 20px;
  left:0;
  bottom:0;
  background:#000;
  color:#fff;
  font-size:14px;
  z-index: 2;
}
.layer{
  position: absolute;
  pointer-events: none;
  left:10px;
  right:10px;
  top:0;
  bottom:30px;
  color:#fff;
}
.left{
  position: absolute;
  bottom:0;
  width:80%;
}
.right{
  position: absolute;
  bottom:0;
  right:0;
  text-align:center;
}
.tag,.title,.pay-video,.des{
  margin-bottom:14px;
}
.tag-w{
  display: inline-block;
  padding:3px 8px;
  background:rgba($color: #000000, $alpha: 0.5);
  border-radius: 4px;
  margin-right:8px;
  margin-top:6px;
  pointer-events: auto;
}
.title{
  display: inline-block;
  pointer-events: auto;
}
.title,.des{
  font-size:14px;
}
.care,.share,.eye{
  margin-bottom:30px;
  pointer-events: auto;
}
.care-icon,.share-icon,.eye{
  width:30px;
  height:30px;
}
.share-icon{
  background:url('../../assets/png/share_white.png') center center no-repeat;
  background-size:100%;
}
.care-icon{
  background: url('../../assets/png/like_white.png') center center no-repeat;
  background-size:100%;
}
.cared{
  background: url('../../assets/png/like_red.png') center center no-repeat;
  background-size:100%;
}
.eye{
  background: url('../../assets/png/eye_open.png') center center no-repeat;
  background-size:100%;
}
.close{
  background: url('../../assets/png/eye_close.png') center center no-repeat;
  background-size:100%;
}
.pay-video{
  display: inline-block;
  padding:4px 8px;
  background:rgba($color: #000000, $alpha: 0.5);
  border-radius: 4px;
  pointer-events: auto;
}
.play-icon{
  display: inline-block;
  width:8.9px;
  height:9.4px;
  background:url('../../assets/png/play_icon.png')center center no-repeat;
  background-size:100%;
  margin-right: 4px;
}
</style>
